import React from 'react';
import { Box, MenuItem, Select, useMediaQuery, useTheme } from '@mui/material';
import searchIcon from '../assets/images/searchIcon.png'

function Navbar({onlanguageChange, language}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


  console.log('language',language);
  
  return (
    <Box sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      display: 'flex',
      height: 55,
      
      // background: 'linear-gradient(to top left, #ffc388, #ffe27d)',
      background: 'linear-gradient(144deg, #FFE27C -2.27%, #FFC28A 79.15%)',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'whitesmoke',
      zIndex: 100
    }}>

      <Box sx={{ position: 'absolute', left: 0, display: 'flex', alignItems: 'center', pl: isSmallScreen ? 2 : 2 }}>
        <img
          src={searchIcon}
          alt="icon"
          style={{ width: 35, marginRight: 35 }}
        />

      </Box>

      <Box sx={{ position: 'absolute', right: 0, display: 'flex', alignItems: 'center', pr: isSmallScreen ? 0.5 : 2 }}>
        <Select
          value={language}
          onChange={onlanguageChange}
          sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none' } }}
          renderValue={() => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {isSmallScreen ?
                <img
                  src={language === 'en' ? '../assets/images/us.png' : '../assets/images/den.png'}
                  alt="flag"
                  style={{ width: 20, marginRight: 8 }}
                />
                :
                <>
                  <img
                    src={language === 'en' ? '../assets/images/us.png' : '../assets/images/den.png'}
                    alt="flag"
                    style={{ width: 20, marginRight: 8 }}
                  />
                  <p>{language === 'en' ? 'English' : 'Dansk'}</p>
                </>
              }
            </div>
          )}
        >
          <MenuItem value="da">
            <img src="../assets/images/den.png" alt="Danish" style={{ width: 20, marginRight: 8 }} />
            Dansk
          </MenuItem>

          <MenuItem value="en">
            <img src="../assets/images/us.png" alt="English" style={{ width: 20, marginRight: 8 }} />
            English
          </MenuItem>
        </Select>
      </Box>

    </Box>
  );
}

export default Navbar