import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useMemo } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Buttons from './button';
import ServiceCard from './serviceCard';
import { t } from 'i18next';
import ServiceCardNew from './serviceCardNew';
import ServiceOfferCardNew from './serviceOfferCard';
import moment from 'moment';

function Services({ category, filteredServices, handleServiceSelect, selectedServiceValue, handleBackPress }) {
  // const isMobile = useMediaQuery('(max-width:600px)');

  // let serviceType = null; 
  // if (category.group === 'Special Services') {
  //   serviceType = 'Special Services';
  // } else if (category.group === 'Cancellation Offers') {
  //   serviceType = 'Cancellation Offers';
  // }


  const type = useMemo(()=>{
    let returnType = 'normal'
    if(category?.group == 'Cancellation offers'){
      returnType = 'cancellationOffers'
    }
    if(category?.group == 'Special offers'){
      returnType= 'specialOffers'
    }

    return returnType
  }, [category?.group])

console.log("serviceee typee" , filteredServices);

  return (
    <Box sx={{pl:0, pr:0, ml:0, mr:0,mt:0, flex:1 , width:'100%'}}>
      <Buttons variant={'outlined'} borderRadius={6} borderWidth={3}  startIcon={<ArrowBackIosIcon    sx={{color:'primary.text'}}/>} onclick={handleBackPress} children={t('Backtocategories')} />
     
      <Typography variant='h6'
                sx={{ fontWeight: 'bold',  paddingBottom: '30px',  textAlign: 'left',mt:4,  fontSize:{xs:'1.1rem'}}}>
                { type == 'cancellationOffers'  ? 'Cancellation offers' :  type == 'specialOffers' ?  'Special offers'  :  t('SelectService') }
            </Typography>
  
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              // padding: '20px',
              backgroundColor: 'white',
              overflowY: 'auto',
              height: 'auto',
            
            }}
          >
            {filteredServices.map(service => (

              // console.log('>>>>>>>>>> ', JSON.stringify(service))

              <Stack spacing={2}  key={service.id}>
                {
                  (type == 'cancellationOffers' || type == 'specialOffers')
                  ?

                  <ServiceOfferCardNew
                  service = {service}
                  onchange={() => handleServiceSelect(service)}
                  checked={selectedServiceValue === `service${service.id}`}
                  type={type}
                  // countdownSeconds={moment(service?.details?.end_datetime).clone().add(24,'hours').diff(moment().utc(), 'seconds')}
                  countdownSeconds={service?.details?.end_datetime ?? moment(service?.created_at).clone().add(24,'hours')}
                />

                  :
                  <ServiceCardNew
                  service = {service}
                  onchange={() => handleServiceSelect(service)}
                  checked={selectedServiceValue === `service${service.id}`}
                  type={type}
                  countdownSeconds={86400}
                />
                }
              
              </Stack>
            ))}
          </Box>

     
    </Box>
  );
}

export default Services;
