import { Box, Card, CardContent, Container, Divider, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

function Hours({ storeDetails }) {

    const weekDays = {
        'Monday' : 'Mandag',
        'Tuesday' : 'Tirsdag',
        'Wednesday' : 'Onsdag',
        'Thursday' : 'Torsdag',
        'Friday' : 'Fredag',
        'Saturday' : 'Lørdag',
        'Sunday' :'Søndag',
    }
    // console.log("keys" , Object.values(weekDays));

    return (
        <Box sx={{pl:0, pr:4}}>
                <Card sx={{ minWidth: 350,maxWidth:350, borderRadius: '25px', boxShadow: '5', mb:10 }}>
                    <CardContent>
                        <Typography variant="h6" sx={{ textAlign: 'center', paddingBottom: '20px' }}>
                            {t('OpeningHours')}
                        </Typography>

                        {storeDetails?.schedules?.map((schedule, i) => (
                            <React.Fragment key={i}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'start',
                                        flexDirection: 'row',
                                        padding: '5px 10px',
                                    }}
                                >
                                    <Typography variant='subtitle1'>{localStorage.getItem('webbooking-lang') === 'da' ? weekDays[schedule.day] : schedule?.day}</Typography>
                                   
                                    {
                                        schedule.open_by_appointment ?
                                            <Typography variant='subtitle1' sx={{ marginLeft: 'auto' }}>
                                              
                                                     {t('openByAppointment')}
                                                  
                                            </Typography>
                                            :
                                            <Typography variant='subtitle1' sx={{ marginLeft: 'auto' }}>
                                                {schedule.is_closed
                                                    ? t('Closed')
                                                    : `${schedule.open_time.slice(0, 5)} - ${schedule.close_time.slice(0, 5)}`}
                                            </Typography>
                                    }
                                </div>
                                {i < storeDetails.schedules.length - 1 && <Divider sx={{ borderColor: 'primary.divider' }} />}
                            </React.Fragment>
                        ))}
                    </CardContent>
                </Card>
            </Box>
   
    );
}

export default Hours;
