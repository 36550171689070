import { Box, Chip, Divider, IconButton, Typography, Grid2, useMediaQuery, Link } from '@mui/material';
import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import Hours from '../components/hours';
import CustomizedSteppers from '../components/Stepper';
import { Outlet } from 'react-router-dom';



function Reviews() {
  const handlepreviouspage = (() => {

  });

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Box>
      <Box>
        {isMobile ? null :
          <Grid2 xs={2} sx={{ margin: '30px  950px 10px 450px', textAlign: 'right', display: 'flex', justifyContent: 'end', alignItems: 'right' }}>
            <CustomizedSteppers />
          </Grid2>}
      </Box>


      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'space-between', md: 'center' }, minHeight: '400px', marginBottom: '20px',alignContent:'space-around', alignItems: {xs:'left', md:'center' }, marginLeft: { xs: '50px' } }}>
        <Grid2 container spacing={20} sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'start' }}>
          <Grid2 item size={{ xs: 12, md: 8 }} sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'left',
            flexDirection: 'column',
            gap: 3,
            minWidth: { xs: '80%', md: '100%' },
            margin: {md:'0 0 0 -360px '},
          }}>
            <Grid2 item size={8} sx={{minWidth:{xs:'300px' ,md:'600px'},width:{xs:'400px' ,md:'850px'}, marginLeft:{xs:'-30px',md:'-20px'} , paddingLeft:{xs:'10px' ,md:'10px' }}}>
              <Outlet />  
            </Grid2>
          </Grid2>

          {/* {isMobile ? null : <Grid2 item size={{ md: 4 }} sx={{ marginRight:'-120px' , padding:'20px', marginTop:'-60px' }}>
              <Hours />
          </Grid2>} */}

        </Grid2>
        
      </Box>

    </Box>
  );
}

export default Reviews;
