import numeral from 'numeral';

const formatAmount = (value, isShort, negativeSymbol) => {
    const decimalPlace = 2;
    const currency = "kr.";
    const decimalFormat = '0'.repeat(decimalPlace);
    
    const formattedValue = numeral(value).format(`${negativeSymbol ? '(' : ''}0.0.${decimalFormat}${isShort ? 'a' : ''}${negativeSymbol ? ')' : ''}`);
    return `${formattedValue} ${currency}`;
};

const formatAmountWithoutCurrency = (value) => {
    const decimalPlace = 2;
    const decimalFormat = '0'.repeat(decimalPlace);
    return numeral(value).format(`0.${decimalFormat}`);
};

const formatQuantity = (value, number = 2) => {
    const decimalFormat = '0'.repeat(number);
    return numeral(value).format(`00.${decimalFormat}`);
};

const formatPercentage = (value) => {
    const decimalFormat = '00';
    return numeral(value).format(`0.${decimalFormat}`) + '%';
};

const formatPercentageWithoutSymbol = (value) => {
    const decimalFormat = '00';
    return numeral(value).format(`0.${decimalFormat}`);
};

const parseAmount = (value, returnInString) => {
    const decimalPlace = 2;
    const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
    const formattedValue = numeral(parsedValue).format(`0.${'0'.repeat(decimalPlace)}`);
    const floatValue = parseFloat(formattedValue);

    return returnInString ? formattedValue : floatValue;
};

export {
    formatAmount,
    formatAmountWithoutCurrency,
    formatQuantity,
    formatPercentage,
    formatPercentageWithoutSymbol,
    parseAmount
};
