import React, { useState } from "react";
import { Avatar, Box, Card, Grid2, Paper, Radio, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import EmployeeCard from "./employeeCard";

function EmployeeHorizontalList({
  employees,
  onEmployeeSelect,
  selectedEmployeeValue,
  employeeSelectionOption
}) {
  const navigate = useNavigate();
  let employeesWithAny = []

  if(!employeeSelectionOption){

    employeesWithAny = [{id:0,name: 'Any/All' }]
  }else{
    employeesWithAny = employees
  }

  console.log('employees', employees);
  

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    ...theme.applyStyles("dark", {
      backgroundColor: "#1A2027",
    }),
    cursor: "pointer",
  }));

  return (
    <Box
      sx={{
        marginBottom: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        gap={{ xs: 0.01, sm: 1, md: 3 }}
        sx={{
          display: "flex",
          justifyContent: "left",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {/* <Grid2 container spacing={2}>
          <Grid2 item xs={3} md={5} overflow="auto">
            <Item onClick={() => onEmployeeSelect('Any/All' ,employees.id === 0)}>
              <Card
                sx={{
                  paddingTop: "10px",
                  border: "2px solid #f7bd98",
                  borderRadius: "15px",
                  textAlign: "center",
                  width: "100%",
                  minWidth: {
                    xs: "100px",
                    sm: "109px",
                    lg: "109px",
                    xl: "109px",
                  },
                  minHeight: { xs: "140px", sm: "145px" },
                  maxHeight: { xs: "140px", sm: "145px" },
                }}
              >
                <Box
                  gap={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "auto",
                    minWidth: {
                      xs: "100px",
                      sm: "109px",
                      lg: "109px",
                      xl: "109px",
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      border: "1px solid black",
                      width: 56,
                      height: 56,
                      position: "relative",
                      overflow: "hidden",
                      backgroundColor:'white',
                      color:'black',
                      fontSize:'30px',
                      fontWeight:'bold'
                    }}
                  >
                    ?
                  </Avatar>
                  <Typography
                    sx={{
                      flexWrap: "wrap",
                      whiteSpace: "normal",
                      maxWidth: {
                        xs: "100px",
                        sm: "109px",
                        lg: "109px",
                        xl: "109px",
                      },
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                      lineHeight: 1,
                    }}
                  >
                    Any/All
                  </Typography>
                  <Radio
                    sx={{ color: "#f7bd98", marginTop: "-10px" }}
                    name="service-radio"
                    value={'Any/All'}
                    checked={null}
                  />
                </Box>
              </Card>
            </Item>
          </Grid2>
        </Grid2> */}
        {employeesWithAny.map((employee, empIndex) => (
          <Grid2 container key={empIndex} spacing={2}>
            <Grid2 item xs={3} md={5} overflow="auto">
              <Item onClick={() => onEmployeeSelect(employee)}>
                <EmployeeCard
                  value={`employee${employee.id}`}
                  employee={employee}
                  checked={selectedEmployeeValue === `employee${employee.id}`}
                />
              </Item>
            </Grid2>
          </Grid2>
        ))}
      </Box>
    </Box>
  );
}

export default EmployeeHorizontalList;
