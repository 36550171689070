import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {

      BusinessNotFound: 'Business not found!',
      LookForOtherBusiness: 'Look for other business',
      SelectService: 'Select service',
      ServiceSelected: 'Service selected',
      Next: 'Next',
      BackToService: 'Back to service',
      SelectStaffMember: 'Select staff member',
      NoStaffFound: 'No staff found',
      SelectTimeDate: 'Select time & date',
      AvailableTimes: 'Available times',
      Information: 'Information',
      Verified: 'Verified',
      YourDetails: 'Your details',
      Name: 'Name',
      EmailAddress: 'Email address',
      Address: 'Address',
      IHaveReadAndAcceptedThe: 'I have read and accepted the',
      conditions: 'conditions',
      IWishToReceiveRelevantInformationViaEMailAndSMS: 'I wish to receive relevant information via e-mail and SMS',
      CancellationPolicy: 'Cancellation policy',
      SeeCondition: 'See condition',
      WeHaveSentYourActivationCodeTo: 'We have sent your activation code to',
      pleaseInsertItBelow: ', please insert it below',
      GoBack: 'Go back',
      CompleteBooking: 'Complete booking',
      Service: 'Service',
      Staff: 'Staff',
      CustomerName: 'Customer name',
      TotalAmount: 'Total amount:',
      ConfirmBooking: 'Confirm booking',
      WelcomeTo: 'Welcome to',
      IfThereAreAnyQuestionsPleaseContactMeAt: 'If there are any questions, please contact me at:',
      Terms: 'Terms',
      IAcceptConditions: 'I accept conditions',
      AppointmentConfirmedWith: 'Appointment confirmed with',
      BookAnotherApointment: 'Book another appointment',
      BookingFailed: 'Booking failed',
      BookingConfirmed: 'Booking confirmed',
      SelectedSlotDoesNotMatchAnyAvailableTimeSlots: 'Selected slot does not match any available time slots',
      PleaseSelectAValidTimeSlot: 'Please select a valid time slot',
      FailedToVerifyOTP: 'Failed to verify OTP',
      OTPVerified: 'OTP verified',
      FailedToSentOTP: 'Failed to sent OTP',
      OTPSentToYourMobileNumber: 'OTP sent to your mobile number',
      EnterAVlidEmailAddress: 'Enter a valid email address',
      Select: 'Select',


      // stores page
      Standard: 'Standard',
      offertonewclints: 'offer to new clints',
      Consulation: 'Consultation',
      Bundleoffers: 'Bundle offers',
      NoclinicsfoundPleasetryadifferentsearch: 'No clinics found. Please try a different search.',
      Found1clinic: 'Found 1 clinic,',
      Searchamong: 'Search among',
      clinics: 'clinics',
      Noservicesfound: 'No services found',
      Found1service: 'Found 1 service,',
      and: 'and + ',
      services: 'services',
      Searchforaclinicorservice: 'Search for a clinic or service...',
      Loading: 'Loading...',
      Nosuggestionsfound: 'No suggestions found.',
      Services: 'Services',
      Outlets: 'Clinics:',
      Nostoresfound: 'No stores found',
      // Extendedsearch: 'Extended search',
      Filtercategories: ' Filter categories',
      Search: ' Search',
      Searchforservice: 'Search for service...',
      AllServices: 'All Services',
      Filterservices: 'Filter services',
      Beautycosmetics: 'Beauty & cosmetics',
      Price: ' Price',
      From: 'From',
      To: ' To',
      Within: 'Within',
      SelectDistance: 'Select Distance',
      Searchtype: 'Search type',
      Showclinics: 'Show clinics',
      searchAmongTitle: 'Search among 573 clinics',
      searchAmoungSubTitle: 'and +30.000 services',

      //clinic services  tab (tabPanel)
      Clinic: 'Clinic',
      Service: 'Service',
      Yoursearchfound49places: 'Your search found 49 places',
      Allshownplacesofferfortreatmentyousearchfor: 'All shown places offer for treatment you search for',
      Choosesearch: 'Choose search',
      Distance: 'Distance',
      Pricelowtohigh: 'Price (low to high)',
      pricehightolow: 'price (high to low)',

      // storeInfo 
      Close: 'Closed',
      Open: 'Open',

      // storeCard
      NoCategories: 'No Categories',
      Visitclinic: 'Visit clinic',
      upto: 'Up to',

      // stepper
      Serviceee: 'Service',
      Employees: 'Employee',
      Time: 'Time',
      Information: 'Information',
      Finished: 'Finished',

      // Services
      Backtocategories: 'Back to categories',
      SelectService: 'Select Service',

      // serviceCardAni
      at: 'At',
      storeName: 'Store Name',
      storeaddresswillsethere: 'store address will set here',
      Bookservice: 'Book service',

      // ServiceCard
      Expireson: 'Expires in',
      ShowLess: 'Show Less',
      ReadMore: 'Read More',

      // otpverification
      Backtoinfo: 'Back to info',
      Approve: 'Approve',

      // Navigator
      Booking: 'Booking',
      booking: 'Booking',
      AboutUs: 'About Us',
      Pictures: 'Pictures',
      Reviews: 'Reviews',

      // maps
      Allowlocationservices: 'Allow location services',

      // Information
      Backtotime: 'Back to time',
      Information: 'Information',
      Firstname: 'First name',
      Lastname: 'Last name',
      Address: 'Address',
      ZipCode: 'Zip Code',
      City: 'City',
      Phonenr: 'Phone nr',
      Email: 'Email',

      // hours
      OpeningHours: 'Opening hours',
      Closed: 'Closed',

      // header
      Reviews: 'Reviews',
      Review: 'Review',

      // employees
      Backtoservices: 'Back to services',
      SelectEmployee: 'Select Employee',
      Noemployeesavailablefortheselectedservice: 'No employees available for the selected service',

      // Policy
      Terms: 'Terms',

      // completeBooking
      Bookingdetails: 'Booking details',
      Service: 'Service:',
      Employee: 'Employee:',
      Bookingtime: 'Booking time:',
      Totalamount: 'Total amount:',
      Confirmbooking: 'Confirm booking',

      // category
      SelectCategory: 'Select Category',
      SpecialOffer: 'Special Offer',
      CancellationOffers: 'Cancellation Offers',

      //calendar
      Backtoemployee: 'Back to employee',
      Selectdateandtime: 'Select date and time',
      clicnic_is_closed: "Clinic is closed on this day",

      // about us
      ContactInformation: 'Contact Information',
      AboutUs: 'About Us',

      //bookingconfirmed
      thankyouforyourbooking: 'Thank you for booking!',

      // Home screen
      // tab panel component
      heading1: 'All shown places offer for treatment you search for',
      chooseSearch: 'Choose search',
      heading2: 'Your search found',
      heading2LastPart: 'places',
      distance: 'Distance',
      priceLtH: 'Price (low to high)',
      priceHtL: 'Price (high to low)',
      servicess: 'Service',
      searchAmongTitle: 'Search among 573 clinics',
      searchAmoungSubTitle: 'and +30.000 services',
      // cards
      upto: 'Up to',
      discount: 'discount',
      visitClinic: 'Visit clinic',
      offers: 'offers',
      offer: 'offer',

      at: 'at ',
      bookService: 'Book service',
      viewCancellationOffer: 'View cancellation offer',

      // moadal
      showClinics: 'Show clinics',

      otherBookingSystem: 'Prices and services may not be correct, since the clinic isn’t a Fiind Partner',
      otherBookingSystempopup: 'Sorry, this clinic is not a partner of Fiind!',
      otherBookingSystempopupMessage: 'To make a booking at this clinic, please click the button below',
      redirectMessage: 'Redirect me to their booking page',
      policies: 'Policies',
      iAcceptPolicies: 'I accept policies',
      bookAnother: 'Book another appointment',

      chooseSort: 'Choose sort',
      searchWithin: 'Search within',
      sortBy: 'Sort by',

      // adv filter
      search: 'Search',
      selected: 'selected',

      // others
      welcomeToFiind: 'Welcome to Fiind',
      weAreOpeningUpOurMarketplaceVerySoon: 'We are opening up our marketplace very soon',
      sortBy: 'Sort by',
      chooseSort: 'Choose sort',
      failedToBookService: 'Failed to book service, try again after some time...',
      noStoresFound: 'No stores found',
      noServicesFound: 'No services found',


      allClinics: 'All clinics',
      allowLocationService: 'To get the full experience, Allow Location Service',
      noCategories: 'No Categories',
      booking: 'Booking',
      aboutUs: 'About Us',
      pictures: 'Pictures',
      reviews: 'Reviews',
      failedToLoadData: "Failed to load Data...",
      service: 'Service', 
      employees: 'Employees',
      time: 'Time', 
      information: 'Information',
      finished: 'Finished',
      // standard: 'Standard',
      // cancellationOffers: 'Cancellation offers',
      // specialOffers: 'Special offers',
      // offerToNewClint: 'Offer to new clints',
      // extendedSearch: 'Extended search',
      // consulation: 'Consulation',
      // bundelOffers: 'Bundel offers',
      chooseSort: "Choose sort",
      searchtype: "Search type",

      "Standard": "Standard",
      "Cancellation offers": "Cancellation offers",
      "Special offers": "Special offers",
      "Offer to new clients": "Offer to new clients",
      "Consultation": "Consultation",
      "Bundle offers": "Bundle offers",
      "Extended search": "Extended search",

       //New translations
       "Note" : "Note",
       "Writeanote" : "Write a note...",
       "Relevantinformation" : "Relevant information",
       "Bookingtimee" : "Booking time:",
       "Totalamountt" : "Total amount:",
       "Servicee": 'Service:',
       'openByAppointment':'Open by appointment',
       'notAcceptingonlineBookings':'Currently, we are not accepting any online bookings.',
        'EnterAVlidPhone': 'Enter a valid phone nr.',
        'birthDay': 'Birthday',
        'cpr': 'CPR',
        'employee_is_not_available': "Employee is not available on this day",


    },
  },

  da: {
    translation: {
      "BusinessNotFound": "Vi har tjekket alle kroge... men fandt ingen klinik på linket",
      "LookForOtherBusiness": "På jagt efter alternativer? Der er masser af klinikker derude!",
      "SelectService": "Vælg behandling",
      "ServiceSelected": "Service selected",
      "Next": "Næste",
      "BackToService": "Tilbage til behandlinger",
      "SelectStaffMember": "Vælg medarbejder",
      "NoStaffFound": "Ingen medarbejdere fundet",
      "SelectTimeDate": "Vælg tid",
      "AvailableTimes": "Ledige tider",
      "Information": "Information",
      "Verified": "Godkendt",
      "YourDetails": "Dine detajler",
      "Name": "Navn",
      "EmailAddress": "Email",
      "Address": "Adresse",
      "IHaveReadAndAcceptedThe": "Jeg har læst og accepterer",
      "conditions": "betingelser",
      "IWishToReceiveRelevantInformationViaEMailAndSMS": "Jeg ønsker at modtage relevant information via e-mail og SMS",
      "CancellationPolicy": "Afbudspolitik",
      "SeeCondition": "Se afbudspolitik",
      "WeHaveSentYourActivationCodeTo": "Din engangskode er sendt til",
      "pleaseInsertItBelow": ", indtast koden nedenfor",
      "GoBack": "Tilbage",
      "CompleteBooking": "Fuldfør booking",
      //   X    "Service": "Service",
      //   X    "Staff": "Staff",
      // X    "CustomerName": "Customer name",
      "TotalAmount": "Total:",
      "ConfirmBooking": "Bekræft booking",
      "WelcomeTo": "Velkommen til",
      "IfThereAreAnyQuestionsPleaseContactMeAt": "Hvis du har spørgsmål, er du velkommen til at kontakte mig på:",
      "Terms": "Vilkår",
      // X    "IAcceptConditions": "I accept conditions",
      // X    "AppointmentConfirmedWith": "Appointment confirmed with",
      "BookAnotherApointment": "Book en ny aftale",
      "BookingFailed": "Booking fejlede",
      "BookingConfirmed": "Booking bekræftet",
      "SelectedSlotDoesNotMatchAnyAvailableTimeSlots": "Det valgte tidspunkt er desværre ikke ledigt",
      "PleaseSelectAValidTimeSlot": "Tidspunktet er ikke ledigt – vælg et andet",
      "FailedToVerifyOTP": "Det lykkedes ikke at bekræfte engangskoden",
      "OTPVerified": "Engangskoden er godkendt",
      "FailedToSentOTP": "Det lykkedes ikke at sende engangskoden",
      "OTPSentToYourMobileNumber": "Engangskoden er sendt til dit mobilnummer",
      "EnterAVlidEmailAddress": "Indtast en gyldig email",
      "Select": "Vælg",
      "Standard": "Standard",
      "offertonewclints": "Tilbud til nye kunder",
      "Consulation": "Konsultation",
      "Bundleoffers": "Klippekort",
      "NoclinicsfoundPleasetryadifferentsearch": "Ingen klinikker fundet – prøv at justere din søgning!",
      "Found1clinic": "Én klinik fundet,",
      "Searchamong": "Søg iblandt",
      "clinics": "klinikker",
      "Noservicesfound": "Ingen behandlinger fundet",
      "Found1service": "Én behandling fundet,",
      "and": "og + ",
      "servicess":"Behandlinger",
      "services": "behandlinger",
      "Searchforaclinicorservice": "Søg efter klinik eller behandling",
      "Loading": "Søger...",
      "Nosuggestionsfound": "Hov, vi kunne ikke finde nogen forslag",
      "Services": "Behandlinger",
      "Outlets": "Klinikker:",
      "Nostoresfound": "Ingen klinikker fundet – prøv at justere din søgning!",
      "Extendedsearch": "Udvidet søgning",
      "Filtercategories": "Filtrer kategorier",
      "Search": "Søg",
      "Searchforservice": "Søg efter behandling...",
      // X    "AllServices": "All Services",
      // X    "Filterservices": "Filter services",
      "Beautycosmetics": "Skønhed og kosmetik",
      "Price": "Pris",
      "From": "Fra",
      "To": "Til",
      "Within": "Afstand",
      // X    "SelectDistance": "Select Distance",
      // X    "Searchtype": "Search type",
      "Showclinics": "Vis klinikker",
      "searchAmongTitle": "Søg iblandt 573 klinikker",
      "searchAmoungSubTitle": "og +30.000 behandlinger",
      "Clinic": "Klinikker",
      "Service": "Behandlinger:",
      //  X   "Yoursearchfound49places": "Your search found 49 places",
      "Allshownplacesofferfortreatmentyousearchfor": "Alle de viste behandlinger og steder har relevans til din søgning",
      "Choosesearch": "Hvad vil du søge efter?",
      "Distance": "Afstand",
      "Pricelowtohigh": "Pris (lav til høj)",
      "pricehightolow": "Pris (høj to lav)",
      "Close": "Lukket",
      "Open": "Åben",
      "NoCategories": "Ingen kategori",
      "Visitclinic": "Besøg klinik",
      "upto": "Op til",
      "Serviceee": "Behandling",
      "Employees": "Medarbejder",
      "Time": "Tid",
      "Information": "Oplysninger",
      "Finished": "Færdig",
      "Backtocategories": "Tilbage til behandlinger",
      "SelectService": "Vælg behandling",
      // X    "at": "At",
      // X    "storeName": "Store Name",
      // X    "storeaddresswillsethere": "Store address will set here",
      // X    "Bookservice": "Book service",
      "Expireson": "Udløber om",
      "ShowLess": "Vis mindre",
      "ReadMore": "Vis mere",
      "Backtoinfo": "Tilbage til info",
      // X    "Approve": "Approve",
      "Booking": "Booking",
      "AboutUs": "Om os",
      "Pictures": "Billeder",
      "Reviews": "Anmeldelser",
      "Allowlocationservices": "Aktivér lokationstjenester",
      "Backtotime": "Tilbage til tid",
      "Firstname": "Fornavn",
      "Lastname": "Efternavn",
      "ZipCode": "Postnummer",
      "City": "By",
      "Phonenr": "Telefonnummer",
      "Email": "Email",
      "OpeningHours": "Åbningstider",
      "Closed": "Lukket",
      "Reviews": "Anmeldelser",
      "Review": "Anmeldelse",
      "Backtoservices": "Tilbage til behandlinger",
      "SelectEmployee": "Vælg medarbejder",
      "Noemployeesavailablefortheselectedservice": "Vi har desværre ingen ledige medarbejdere til denne behandling.",
      // X    "Terms": "Terms",
      "Bookingdetails": "Booking detaljer",
      "Employee": "Medarbejder:",
      // "Bookingtime": "Tidspunkt:",
      "Totalamount": "Total:",
      "Confirmbooking": "Bekræft booking",
      "SelectCategory": "Vælg kategori",
      "SpecialOffer": "Special Offer",
      "CancellationOffers": "Afbudstider",
      "Backtoemployee": "Tilbage til medarbejder",
      "Selectdateandtime": "Vælg tid",
      "clicnic_is_closed": "Klinikken er lukket på denne dag",
      "ContactInformation": "Contact Information",
      "thankyouforyourbooking": "Tak for din booking!",
      "heading1": "Alle de viste behandlinger og steder har relevans til din søgning",
      "chooseSearch": "Hvad vil du søge efter?",
      "heading2": "Din søgning fandt",
      "heading2LastPart": "klinikker",
      "distance": "Afstand",
      "priceLtH": "Pris (lav til høj)",
      "priceHtL": "Price (høj til lav)",
      "service": "behandlinger:",
      "searchAmongTitle": "Søg iblandt 573 klinikker",
      "searchAmoungSubTitle": "og +30.000 behandlinger",
      "upto": "Op til",
      "discount": "rabat",
      "visitClinic": "Besøg klinik",
      "offers": "tilbud",
      "offer": "tilbud",
      "at": "at ",
      "bookService": "Book behandling",
      "viewCancellationOffer": "Se afbudstid",
      "showClinics": "Vis klinikker",
      "otherBookingSystem": "OBS: Priser og behandlinger kan variere, da klinikken ikke er en Fiind-partner endnu.",
      "otherBookingSystempopup": "Ups! Denne klinik er ikke med i Fiind-familien endnu",
      "otherBookingSystempopupMessage": "Vil du booke en tid? Tryk på knappen nedenfor",
      "redirectMessage": "Send mig videre til deres bookingside!",
      "policies": "Politik",
      "iAcceptPolicies": "Jeg accepterer politik",
      "bookAnother": "Book en ny tid",
      // X    "chooseSort": "Choose sort",
      "searchWithin": "Afstand",
      "sortBy": "Sorter efter",
      "search": "Søg",
      // X    "selected": "selected",
      "welcomeToFiind": "Velkommen til Fiind",
      "weAreOpeningUpOurMarketplaceVerySoon": "Vi åbner op for vores markedsplads meget snart!",
      "failedToBookService": "Bookingen fejlede... Prøv igen senere",
      "noStoresFound": "Ingen klinikker fundet – prøv at justere din søgning!",
      "noServicesFound": "Ingen behandlinger fundet – prøv at justere din søgning!",

      "allClinics": "Alle klinikker",
      "allowLocationService": "For den bedste oplevelse, slå lokationstjenester til.",
      "noCategories": "Ingen kategorier",
      "booking": "Booking",
      "aboutUs": "Om os",
      "pictures": "Billeder",
      "reviews": "Anmeldelser",
      "failedToLoadData": "Ups! Vi kunne ikke finde indholdet – prøv igen",
      "service": "Behandlinger",
      "employees": "Medarbejdere",
      "time": "Tidspunkt",
      "information": "Information",
      "finished": "Færdig",
      // X      "standard": "Standard",
      // "cancellationOffers": "Afbudstider",
      // "specialOffers": "Special offers",
      // "offerToNewClint": "Tilbud til nye kunder",
      // "extendedSearch": "Udvidet søgning",
      // "consulation": "Konsultation",
      // "bundelOffers": "Klippekort",
      "chooseSort": "Sorter efter",
      "searchtype": "Søgetype",

      "Standard": "Standard",
      "Cancellation offers": "Afbudstider",
      "Special offers": "Special offers",
      "Offer to new clients": "Tilbud til nye kunder",
      "Consultation": "Konsultation",
      "Bundle offers": "Klippekort",
      "Extended search": "Udvidet søgning",

      //New information component translations
      "Note" : "Note",
      "Writeanote" : "Skriv en note...",
      "Relevantinformation" : "Relevant information",
      "Bookingtimee" : "Booking tid:",
      "Totalamountt" : "Total:",
      'openByAppointment':'Open by appointment',
       'notAcceptingonlineBookings':'Currently, we are not accepting any online bookings.',
       'EnterAVlidPhone': 'Enter a valid phone nr.',
       'birthDay': 'Birthday',
       'cpr': 'CPR',
       'employee_is_not_available': "Employee is not available on this day",



      // "Bookingtimee" : "Booking time:",
    },
  },
};

const getLanguage = () => {
  // return localStorage.getItem('webbooking-lang') || 'en';
  return localStorage.getItem('webbooking-lang');
};

i18n.use(initReactI18next).init({
  resources,
  lng: getLanguage(),
  fallbackLng: localStorage.getItem('webbooking-lang'),
  interpolation: {
    escapeValue: false,
  },
});
