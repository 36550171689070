import { Alert, Box, Grid, Grid2, TextField, Typography } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import Buttons from './button';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { webCustomerBookingApi } from '../apis/web-customerBooking';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import CustomizedSnackbars from '../components/customizedSnackbars';

function OtpVerification(props) {
    const { handleBackPress, customerData, otpVerified, setOtpVerfied, setSaveToken, otpStatus, setOtpStatus } = props;
    const [values, setValues] = useState(Array(4).fill(''));
    const [loadingButton, setLoadingButton] = useState(false);
    const [alerts, setAlerts] = useState([]);
    const inputRefs = useRef([]);
    const phonenumber = customerData.phoneNumber;
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('Something went wrong');

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };




    const handleChange = (e, index) => {
        const newValues = [...values];
        newValues[index] = e.target.value;

        if (e.target.value && index < 3) {
            inputRefs.current[index + 1].focus();
        }

        setValues(newValues);

        if (newValues.every(value => value !== '')) {
            requestValidation(newValues.join(''));
        }
    };
    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && index > 0 && e.target.value === '') {
            inputRefs.current[index - 1].focus();
        }
    };

    const addAlert = (severity, message) => {
        const newAlert = { id: new Date().getTime(), severity, message };
        setAlerts([newAlert]);
    };

    useEffect(() => {
        if (otpStatus === 'success') {
            addAlert('success', t('OTPSentToYourMobileNumber'));
        } else if (otpStatus === 'failure') {
            addAlert('error', t('FailedToSentOTP'));
            setSnackbarOpen(true);
        }
    }, [otpStatus]);

    const requestValidation = async (code) => {
        const country_code = "+45";
        const phone_number = customerData.phoneNumber;
        const otp = code;

        setLoadingButton(true);
        try {
            const response = await webCustomerBookingApi.verifyOTP(country_code, phone_number, otp);
            console.log('HERE IS THE OTP VERIFICATION ', response);
            const { success, msg } = response
            if (success) {

                setSnackbarMsg(msg)
                setSaveToken(response?.data?.token)
                setLoadingButton(false);
                addAlert('success', t('OTPVerified'));
                setOtpVerfied(true);
                return { success: true };
            } else {
                setSnackbarMsg(msg)

                setLoadingButton(false);
                setSnackbarOpen(true);
                setOtpVerfied(false);
                addAlert('error', msg);
                return { success: false };
            }
        } catch (err) {
            setLoadingButton(false);
            setSnackbarOpen(true);
            setOtpVerfied(false);
            addAlert('error', err?.detail);
            return { success: false };
        }
    };


    return (
        <Box sx={{ pl: 0, pr: 0, ml: 0, mr: 0, mt: 0, flex: 1 }} flexDirection={'column'} flex={1} justifyContent={'center'}>
            {/* <CustomizedSnackbars
                open={snackbarOpen}
                message={snackbarMsg}
                onClose={handleSnackbarClose}
            /> */}
            <Buttons variant={'outlined'} borderRadius={6} borderWidth={3} startIcon={<ArrowBackIosIcon />} onclick={handleBackPress} children={t('Backtoinfo')} />

            {alerts.map(alert => (
                <Box sx={{ mb: 2, mt: 2 }}>
                    <Alert
                        key={alert.id}
                        severity={alert.severity}
                    >
                        {alert.message}
                    </Alert>
                </Box>
            ))}


            <Box sx={{ marginTop: '20px' }}>
                <Grid2 gap={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: { xs: 'start', md: 'center' } }}>
                    <Grid2 size={{ xs: 12, md: 12 }} flex={1} flexDirection={'row'} justifyContent={'flex-start'} >
                        <Typography variant='subtitle1' color='#545454' sx={{ textAlign: { xs: 'start', md: 'center' } }} fontSize= '1rem' align='center'>{t('WeHaveSentYourActivationCodeTo')} <strong>+45 {phonenumber}</strong>{t('pleaseInsertItBelow')}</Typography>
                    </Grid2>

                    <Grid2 size={{ xs: 12, md: 12 }}>
                        <Box sx={{ display: 'flex', gap: { xs: 2, sm: 4 }, justifyContent: { xs: 'flex-start', md: 'center' }, alignItems: 'center' }}>
                            {values.map((values, index) => (

                                <TextField
                                    inputRef={ref => inputRefs.current[index] = ref}
                                    onChange={(e) => handleChange(e, index)}
                                    value={values}
                                    inputProps={{
                                        maxLength: 1,
                                        style: { textAlign: 'center', padding: 0 },
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                        autoComplete: 'one-time-code',
                                        onKeyDown: (e) => handleKeyDown(e, index),
                                    }}
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        fontSize: '24px',
                                        backgroundColor: 'white',
                                        borderRadius: '15px',
                                        margin: 0,
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '15px',
                                            '& fieldset': {
                                                border: '2px solid #f7bd98',
                                            },
                                            '&:hover fieldset': {
                                                border: '3px solid #f7bd98',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: '3px solid #f7bd98',
                                            },
                                        },
                                        '& .MuiInputBase-root': {
                                            height: '100%',
                                            borderRadius: '15px',
                                            border: '3px #ffbc97',
                                        },
                                    }}
                                />
                            ))}
                        </Box>
                    </Grid2>
                    {/* <Grid2 size={{xs:12, md:12}} sx={{ display: { md: 'flex' }, justifyContent: { md: 'center' } }}>
                        <Buttons variant={'contained'} children={t('Approve')} disabled={loadingButton} loading={loadingButton} borderRadius={{ xs: '20px', md: '12px' }} fontWeight={'bold'} gap={1} width={{ xs: '40%', md: 'auto' }} />
                    </Grid2> */}
                </Grid2>
            </Box>
        </Box>
    )
}

export default OtpVerification;
