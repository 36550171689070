import { Box, Button, Container, Grid2, Modal, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import Hours from '../components/hours';
import CustomizedSteppers from '../components/Stepper';
// import { useOutletContext } from 'react-router-dom';
import Category from '../components/category';
import Services from '../components/services';
import Employee from '../components/employee';
import Calendar from '../components/calendar';
import dayjs from 'dayjs';
import Information from '../components/information';
import OtpVerification from '../components/otpVerification';
import CompleteBooking from '../components/completeBooking';
import toast from 'react-hot-toast';
import BookingConfirmed from '../components/bookingConfirmed';
import { webCustomerBookingApi } from '../apis/web-customerBooking';
import CustomizedSnackbars from '../components/customizedSnackbars';
import { t } from 'i18next';
import theme from '../theme';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';



function Booking({ web_store_name, selectedStep, setSelectedStep, outletId, setOutletId, storeDetails, open, setOpen, categorySelected, serviceSelected }) {
  // const {
  //   web_store_name, selectedStep, setSelectedStep, outletId, setOutletId, storeDetails, open, setOpen, categorySelected, serviceSelected} = useOutletContext();

  console.log('STORE DETAILS ', JSON.stringify(storeDetails));

  const [selectedCategory, setSelectedCategory] = useState(categorySelected);
  const [selectedService, setSelectedService] = useState(serviceSelected);
  const [selectedServiceValue, setSelectedServiceValue] = useState(serviceSelected ? `service${serviceSelected.id}` : null);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [selectedEmployeeValue, setSelectedEmployeeValue] = useState(null);
  const [employeeHours, setEmployeeHours] = useState(true);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [customerData, setCustomerData] = useState({
    customerFirstName: '',
    customerLastName: '',
    customerEmail: '',
    customerAddress: '',
    zipCode: '',
    city: '',
    phoneNumber: '',
    note: '',
    dateOfBirth: null,
    cprNumber: '',
    checkstate: false,
  });
  const [timeSchedule, setTimeSchedule] = useState([]);
  const [otpVerified, setOtpVerfied] = useState(false);
  const [saveToken, setSaveToken] = useState('');
  const [showbookingConfirmedDialog, setShowBookingConfirmedDialog] = useState(false);
  const [bokingConfirmedData, setBookingConfirmData] = useState(null);
  const [confirmBookingLoader, setConfirmBookingLoader] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [otpStatus, setOtpStatus] = useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // console.log('categoryselected', categorySelected);
  // console.log('serviceSelected', serviceSelected);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleCategorySelect = (category) => {
    console.log('category', category);

    setSelectedCategory(category);
    setSelectedStep('category1');
  };

  const handleServiceSelect = (service) => {

    console.log('service123123', service);
    
    
    
    if (!storeDetails?.allow_online_booking) {

   

      return null

    }



    if (storeDetails?.booking_system != "Fiind") {

      handleOpen(true)

      return null

    }

    

    setSelectedService(service);
    setSelectedServiceValue(`service${service.id}`);

    if (service?.cancellation_offer_booking_source_id !== null) {

      const employee = service?.cancellation_offer_employee
      setSelectedEmployee(employee);
      setSelectedEmployeeValue(`employee${employee.id}`);


      const bookingDate = moment(service?.cancellation_offer_slot_start).format('YYYY-MM-DD')
      const getCancellationSlotStart = service?.cancellation_offer_slot_start.split('T')[1]
      const getCancellationSlotEnd = service?.cancellation_offer_slot_end.split('T')[1]
      const cancllationSlot = `${getCancellationSlotStart.slice(0, -3)} - ${getCancellationSlotEnd.slice(0, -3)}`


      setSelectedSlot(cancllationSlot)
      setSelectedDate(service?.cancellation_offer_slot_start)
      console.log('cancllationSlot', cancllationSlot);
      console.log('bookingDate', bookingDate);

      setSelectedStep('calendar');
      return true

    }

    if (service?.is_equipment_service) {
      setSelectedStep('employee');

    } else {


      setSelectedStep('service');
    }
  };

  const handleBackPress = (currentScreen) => {
    // console.log('currentSCreen', currentScreen);

    switch (currentScreen) {
      case "category1":
        setSelectedStep('category');
        break;
      case "service":
        setSelectedStep('category1');
        break;
      case "employee":
        if (selectedService?.is_equipment_service) {
          setSelectedStep('category1');

        } else {

          setSelectedStep('service');
        }
        break;
      case "calendar":
        if (selectedService?.cancellation_offer_booking_source_id !== null) {
          setSelectedStep('category1');
          return true

        } else {

          setSelectedStep('employee');

        }
        break;
      case "information":

        setSelectedStep('calendar');
        break;
      case "otpverification":
        setSelectedStep('information');
        break;
      case "confirmbooking":
        setSelectedStep('confirmbooking');
        break;
    }

  }

  const handleEmployeeSelect = (employee) => {
    setSelectedEmployee(employee);
    setSelectedEmployeeValue(`employee${employee.id}`);
    setSelectedStep('employee');
  };

  const handleDateSelect = (date) => {
    setSelectedSlot(null)
    setSelectedDate(date);
  };

  const handleSlotSelect = (selectedSlot) => {
    console.log('selectedSlot11', selectedSlot);

    setSelectedSlot(selectedSlot)
  }

  // const bookingDetails = {
  //   outletId,
  //   selectedCategory,
  //   selectedService,
  //   selectedEmployee,
  //   selectedDate,
  //   selectedSlot,
  //   customerData,
  // };

  const bookingDetails = useMemo(() => {
    return {
      outletId: outletId,
      selectedCategory: selectedCategory,
      selectedService: selectedService,
      selectedEmployee: selectedEmployee,
      selectedDate: selectedDate,
      selectedSlot: selectedSlot,
      customerData: customerData,
      employeeHours:employeeHours,
    }

  }, [outletId,
    selectedCategory,
    selectedService,
    selectedEmployee,
    selectedDate,
    selectedSlot,
    customerData,])

  const handlenextPage = () => {
    setSelectedStep('calendar');
  };

  const handleotpPage = () => {
    if(!storeDetails?.marketplace_require_otp){
      setSelectedStep('otpverification');
      return
    }
    setSelectedStep('information');
  };

  useEffect(() => {
    if (otpVerified === true) {
      setTimeout(() => {
        setSelectedStep('otpverification');
      }, 700);
    } else {
      null
    }
  }, [otpVerified])

  const findMatchingSlot = (selectedSlot) => {
    return timeSchedule.find((slot) => {
      const [start, end] = slot.split(' - ');
      return selectedSlot == start;
    }) || null;
  };

  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);

  };


  const confirmBooking = async () => {
    console.log('heree', selectedSlot);

    if (!selectedSlot) {
      toast.error(t('PleaseSelectAValidTimeSlot'));
      return { success: false };
    }

    let matchingSlot = null
    if (selectedService?.cancellation_offer_booking_source_id == null) {

      matchingSlot = findMatchingSlot(selectedSlot);
      if (!matchingSlot) {
        toast.error(t('SelectedSlotDoesNotMatchAnyAvailableTimeSlots'));
        return { success: false };
      }
    } else {
      matchingSlot = selectedSlot
    }

    console.log('selectedDate', selectedDate);


    const booking_date = dayjs(selectedDate).format('YYYY-MM-DD');
    const customer_name = customerData.customerFirstName;
    const customer_phone_number = customerData.phoneNumber;
    const note = customerData.note;
    const employee_id = selectedEmployee?.id ?? null;
    const service_id = selectedService.id;
    const time_slot = matchingSlot;
    const total_amount = (selectedEmployee?.id === 0 || selectedEmployee?.id == null || selectedEmployee?.id == undefined) ? selectedService.price : selectedEmployee?.price ?? null;



    console.log('outletId', outletId);
    console.log('bookingDate', booking_date);
    console.log('customer_name', customer_name);
    console.log('customer_phone_number', customer_phone_number);
    console.log('employee_id', employee_id);
    console.log('service_id', service_id);
    console.log('time_slot', time_slot);
    console.log('total_amount', total_amount);
    console.log("note 2 >>>" , note);
    

    setConfirmBookingLoader(true);

    try {

      let source = searchParams.get('source')
    
      console.log('searchParams',  source ?? 'DIRECTWEBSTORE' );

      let visitorToken = localStorage.getItem("visitorToken")
        if(visitorToken){
            console.log('visitorToken', visitorToken);
        }else{
            newVToken = uuidv4();

            localStorage.setItem("visitorToken", newVToken);
            visitorToken = newVToken
        }

      const response = await webCustomerBookingApi.confirmBooking(outletId, saveToken, booking_date, customer_name, customer_phone_number, note, employee_id ?? null, service_id, time_slot, total_amount, source ?'WEBMARKETPLACE': 'DIRECTWEBSTORE', visitorToken);
      console.log('HERE IS THE CONFIRM BOOKING RESPONCE ', response);
      setSaveToken(response?.data?.token)
      console.log('response', response);

      if (response?.success) {
        // const data = {
        //   timeSlot: matchingSlot,
        //   serviceDetails: selectedService,
        //   employee: selectedEmployee,
        //   storeDetails: storeDetails
        // };

        const { data: bookingData } = response
        const { booking_detail, booking_datetime_start } = bookingData

        const startTime = booking_datetime_start.split('T')[1]
        const selectedSlot = `${startTime.slice(0, -3)}`

        const data = {
          storeDetails: storeDetails,
          serviceName: booking_detail?.service_name,
          employee_name: booking_detail?.employee_name,
          selectedDate: booking_datetime_start,
          selectedSlot: selectedSlot,
          amount: bookingData?.total_amount	
        }


        setBookingConfirmData(data);
        setConfirmBookingLoader(false);
        setSelectedStep('confirmbooking')
        return { success: true };
      } else {
        const { msg } = response
        console.log('Booking failed error ', msg)
        setSnackbarOpen(true);
        //setLoadingButton(false);
        //addConfirmBookingAlerts('error', t('BookingFailed'), err.detail)
        setConfirmBookingLoader(false);
        return { success: false };
      }

      addConfirmBookingAlerts('success', '', t('BookingConfirmed'))



      setTimeout(() => {
        setSelectedService(null);
        setSelectedEmployee(null);
        setSelectedSlot(null);
        setCustomerData(''),
          setSelectedStep('category');
        //setCustomerFirstName('');
        //setPhoneNumber('');
        //setCustomerAddress('');
        //setCustomerEmail('');
        //setNumberEntered(false);
        //setValues(Array(4).fill(''));
        setSaveToken('');
        // setCheckedState({
        //     cancelPolicies: false,
        //     marketingPermission: false,
        //     confirmationPolicies: false,
        // });
      }, 2000);

    } catch (err) {
      console.log('Booking failed error ', err)
      setSnackbarOpen(true);
      //setLoadingButton(false);
      //addConfirmBookingAlerts('error', t('BookingFailed'), err.detail)
      setConfirmBookingLoader(false);
      return { success: false };
    }
  };

  const resetBooking = () => {
    setSelectedCategory(null)
    setSelectedServiceValue(null)
    setSelectedService(null)
    setSelectedEmployee(null)
    setSelectedEmployeeValue(null)
    setSelectedSlot(null)
    setSelectedStep('category')
    setSelectedDate(dayjs())
    setOtpStatus(null)
    setBookingConfirmData(null)
    setOtpVerfied(false)
    setTimeSchedule([])

  }

  useEffect(() => {
    if (bokingConfirmedData != null || bokingConfirmedData != undefined) {
      setShowBookingConfirmedDialog(true);
    } else {
      setShowBookingConfirmedDialog(false);
    }
  }, [bokingConfirmedData]);


  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <Container sx={{ pl: 0, pr: 0, mb: 8 }}>
      <CustomizedSnackbars
        open={snackbarOpen}
        message={t('failedToBookService')}
        onClose={handleSnackbarClose}
      />
      <Box>
        {!isMobile && (
          <Stack
            sx={{
              width: '100%',
              alignItems: 'center',
              mt: 4,
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <CustomizedSteppers selectedStep={selectedStep} setSelectedStep={setSelectedStep} web_store_name={web_store_name} />

          </Stack>
        )}
      </Box>


      <Stack flex={1} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'center'} sx={{ mt: { xs: 2, md: 2 } }}>

        {
          storeDetails?.booking_system != "Fiind"
          &&
          <Typography variant='body1' sx={{ textAlign: 'left', mb: 2, flex: 1, width: '100%', fontStyle: 'italic', color: 'text.secondary' }}>*{t('otherBookingSystem')}*</Typography>

        }

        {
          ! storeDetails?.allow_online_booking 
          &&
          <Typography variant='body1' sx={{ textAlign: 'left', mb: 2, flex: 1, width: '100%', fontStyle: 'italic', color: '#df013b' }}>*{t('notAcceptingonlineBookings')}*</Typography>

        }


        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'space-between', md: 'center' },
          marginBottom: '20px',
          alignItems: { xs: 'left', md: 'center' },
          flex: 0.3,
          ml: 0,
          mr: 0,
          width: '100%'


          // marginLeft: { xs: '20px', sm: '30px', md: '0px' },
          // padding: { xs: '40px', sm: '30px', md: '40px' },
        }}>



          {selectedStep === 'category' && (
            <Category handleCategorySelect={handleCategorySelect} storeDetails={storeDetails} selectedCategory={selectedCategory} />
          )}
          {selectedStep === 'category1' && (
            <Services
              category={selectedCategory}
              selectedServiceValue={selectedServiceValue}
              handleServiceSelect={handleServiceSelect}
              filteredServices={selectedCategory?.services ?? []}
              handleBackPress={() => handleBackPress(selectedStep)}
            />
          )}
          {selectedStep === 'service' && (
            <Employee
            onlineEmployeeList={storeDetails?.online_employees ?? []}
              employeeSelectionOption = {storeDetails?.marketplace_employee_selection ?? true}
              selectedService={selectedService}
              selectedEmployeeValue={selectedEmployeeValue}
              handleEmployeeSelect={handleEmployeeSelect}
              handleBackPress={() => handleBackPress(selectedStep)}
            />
          )}
          {selectedStep === 'employee' && (
            <Calendar
              individualOpeningHours={storeDetails?.individual_opening_hour ?? false}
              handlenextPage={handlenextPage}
              handleDateSelect={handleDateSelect}
              storeDetails={storeDetails}
              bookingDetails={bookingDetails}
              handleSlotSelect={handleSlotSelect}
              handleBackPress={() => handleBackPress(selectedStep)}
              schedule={timeSchedule}
              setschedule={setTimeSchedule}
              selectedSlot={selectedSlot}
              selectedService={selectedService}
            />
          )}
          {selectedStep === 'calendar' && (
            <Information
              handleBackPress={() => handleBackPress(selectedStep)}
              handleotpPage={handleotpPage}
              storeDetails={storeDetails}
              customerData={customerData}
              selectedService={selectedService}
              setCustomerData={setCustomerData}
              setOpen={setOpen}
              otpStatus={otpStatus}
              setOtpStatus={setOtpStatus}
            />
          )}
          {selectedStep === 'information' && (
            <OtpVerification
              handleBackPress={() => handleBackPress(selectedStep)}
              customerData={customerData}
              otpVerified={otpVerified}
              setOtpVerfied={setOtpVerfied}
              setSaveToken={setSaveToken}
              otpStatus={otpStatus}
              setOtpStatus={setOtpStatus}
            />
          )}
          {selectedStep === 'otpverification' && (
            <CompleteBooking
            handleBackPress={() => handleBackPress('information')}

              bookingDetails={bookingDetails}
              confirmBooking={confirmBooking}
              confirmBookingLoader={confirmBookingLoader}
            />
          )}
          {selectedStep === 'confirmbooking' && (
            <BookingConfirmed
              bookingDetails={bokingConfirmedData}
              bookAnother={() => resetBooking()}
            />
          )}

        </Box>
      </Stack>


      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box flex={1} flexDirection={'column'} justifyContent={'center'} sx={{

          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',

          bgcolor: 'background.paper',
          border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: '20px',
          boxShadow: 24,
          pt: 2,
          px: 4,
          pb: 3,
          width: { xs: '90%', md: 550 }
        }}>
          <Stack flex={1} justifyContent={'center'} alignItems={'center'} >

            <Typography variant='h6' sx={{ width: '100%', textAlign: 'center' }} >{t('otherBookingSystempopup')}</Typography>

            <Typography variant='body1' sx={{ color: 'text.secondary', width: '100%', textAlign: 'center', mt: 2 }}>{t('otherBookingSystempopupMessage')}</Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={() => { handleClose(); window.open(storeDetails?.other_booking_system) }}
              sx={{ mt: 4, width: '60%', color: 'white', textTransform: 'initial' }}

            >
              {t('redirectMessage')}
            </Button>

          </Stack>

        </Box>
      </Modal>

    </Container>
  );
}

export default Booking;
